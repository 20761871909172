<script setup lang="ts">
import ImgComponent from '@/components/media/ImgComponent.vue'
import { ApplicationMode } from '@/enums/ApplicationMode'
import { defineProps, ref } from 'vue'
import type { PropType } from 'vue'
import { IVideo } from '@/interface/IImage'

defineProps({
  title: {
    type: String,
    required: false,
  },
  describe: {
    type: String,
    required: false,
  },
  mode: {
    type: String as PropType<ApplicationMode>,
    required: false,
    default: ApplicationMode.WithColor,
  },
  background: {
    type: [Object, String] as PropType<IVideo | string>,
    required: false,
  },
})
const playVideo = ref(false)
const AsyncVideoComponent = defineAsyncComponent(() =>
  import('@/components/media/VideoComponent.vue')
);
</script>

<template>
  <div
    :class="`app__container bg-${background} ${mode}`"
    @click="playVideo = !playVideo"
  >
    <div :class="`app__container__${mode}`">
      <div :class="`app__container__${mode}-img`">
        <ImgComponent
          v-if="
            mode === ApplicationMode.Header || 
            mode === ApplicationMode.Short ||
            mode === ApplicationMode.ShortWithCard
          "
          :src="background"
          alt="background-header"
        />
      </div>
      <AsyncVideoComponent
        v-if="mode === ApplicationMode.Video"
        :src-video="background as IVideo"
        :active="playVideo"
      />
      <div v-if="mode === ApplicationMode.Circle" class="circle" />
    </div>

    <div class="app__container__content">
      <div v-if="title" class="app__container__content-title" v-text="title" />
      <div
        v-if="describe"
        class="app__container__content-copy"
        v-text="describe"
      />
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@mixin shade {
  content: '';
  display: block;
  background: var(--color-backgorund-x);
  background: -moz-linear-gradient(#032b4d 65%, #6d6d6d1e);
  background: -webkit-linear-gradient(#032b4d 65%, #6d6d6d1e);
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}

@mixin header {
  z-index: -10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-color {
  min-height: 200px !important;
}

.app__container {
  min-height: 400px;
  width: 100vw;

  &__header {
    @include header;

    img {
      width: 100%;
      height: 100%;
    }

    &-img {
      width: 100%;
      height: 100%;
      position: relative;

      &::before {
        @include shade;
      }

      img {
        object-position: center;
        object-fit: cover;
      }
    }
  }

  &__short {
    @include header;

    &-img {
      width: 100%;
      height: clamp(650px, 56vh, 900px);
      position: relative;

      &::before {
        @include shade;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }

  &__short-card {
    @include header;

    &-img {
      width: 100%;
      position: relative;
      height: clamp(650px, 56vh, 900px);

      &::before {
        @include shade;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -10;
  }

  &__circle {
    position: absolute;
    z-index: -20;
    width: 100vw;
    min-height: 200vh;
    overflow: hidden;
    margin-top: -300px;

    .circle {
      width: 100%;

      &::before,
      &::after {
        z-index: -1;
        position: absolute;
        content: '.';
        width: 200vw;
        aspect-ratio: 1/1;
        top: 0;
        left: 50%;
        transform: translate(-50%, 10%);
        border: 1px solid #044d74;
        opacity: 0.2;
        border-radius: 50%;
      }

      &::after {
        transform: translate(-50%, 10%) scale(0.53);
      }
    }
  }

  &__content {
    width: 94%;
    margin: 0 auto;
    padding: 2.5rem 2%;
    transition: width 0.3s ease;
    overflow-x: clip;

    &-title {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
      color: var(--vt-c-text-dark);
      margin: 0 auto 1rem;
    }

    &-copy {
      color: var(--vt-c-black);
      text-align: center;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 750px) {
  .circle {
    &::before,
    &::after {
      width: 180vw;
      max-width: 1400px;
    }
  }

  .app__container__content {
    margin: 0 auto;
    padding: 3rem 12px;
    width: 89.5%;

    &-title {
      font-size: 36px;
      line-height: 50px;
      max-width: 65%;
    }

    &-copy {
      max-width: 100%;
      line-height: 28.8px;
    }
  }
}

@media screen and (min-width: 1300px) {
  .app__container__content {
    max-width: 1235px;
  }
}

@media screen and (min-width: 1550px) {
  .app__container__content {
    max-width: 76.5%;
  }
}
</style>
